
.headers[data-v-511c4e0c] {
  background: #ffffff;
  padding: 14px 12px;
}
.headers[data-v-511c4e0c] .el-button {
  height: 28px;
}
[data-v-511c4e0c] .el-button + .el-button {
  margin-left: 0px;
}

